import React from "react";
import "./SobreFortAgro.css";

const SobreFortAgro = () => {
  return (
    <section className="sobre-fortagro">
      <h1>Bem-vindo à Fort Agro</h1>
      <p>
        Bem-vindo à <strong>Fort Agro</strong>, sua fonte confiável para
        equipamentos de ordenha de alta qualidade! Somos uma empresa dedicada a
        fornecer aos produtores de leite as melhores soluções em ordenhadeiras,
        de forma prática e conveniente, diretamente para sua fazenda.
      </p>
      <p>
        Aqui na <strong>Fort Agro</strong>, entendemos a importância de contar
        com equipamentos eficientes e duráveis para otimizar o processo de
        ordenha e garantir a qualidade do leite. Nossa loja virtual oferece uma
        ampla variedade de ordenhadeiras modernas, projetadas para atender às
        necessidades específicas dos produtores, desde pequenas propriedades até
        grandes fazendas leiteiras.
      </p>
    </section>
  );
};

export default SobreFortAgro;
