import Acao from "../../componentes/acao/acao";
import DiferenciaisProduto from "../../componentes/diferenciaisProduto/DiferenciaisProduto";
import Footer from "../../componentes/footer/footer";
import Header from "../../componentes/header/header"
import PreeFooter from "../../componentes/preeFooter/preeFooter";
import Produtos from "../../componentes/produtos/produtos";
import SobreFortAgro from "../../componentes/sobreFortAgro/SobreFortAgro";






const PagHome = () => {
    return (
        <>
            <Header />
            <SobreFortAgro/>
            <Produtos />
            <Acao />
            <DiferenciaisProduto/>
            {/* <PreeFooter /> */}
            <Footer/>
        </>
    )
}

export default PagHome;