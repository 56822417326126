import React from "react";
import "./footer.css";
import LogoDev from "../../../Images/logoVWA.png";
import WhatsAppButton from "../WhatsAppButton/WhatsAppButton";

const Footer = () => {
  let dataAtual = new Date();

  let anoAtual = dataAtual.getFullYear();

  return (
    <footer className="footer">
      <div className="footer-container">
        <p>
          © {anoAtual} <strong>Ordenhadeiras Fort Agro</strong>. Todos os
          direitos Reservados.
        </p>
        <div className="footer-developed">
          <span>Desenvolvido por</span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.vwasystems.com.br"
          >
            <img src={LogoDev} alt="Desenvolvedor" />
          </a>
        </div>
      </div>
      <WhatsAppButton />
    </footer>
  );
};

export default Footer;
