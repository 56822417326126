import fixaFoto from "../../../Images/fixa.png";
import movelFoto from "../../../Images/movel.png"
import { whatsappUrl } from "../utils/utils";

const Produtos = () => {
  return (
    <>
      <div class="container col-xxl-8 px-4 py-5">
        <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div class="col-10 col-sm-8 col-lg-6">
            <img
              src={fixaFoto}
              class="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
          <div class="col-lg-6">
            <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">
              Ordenhadeira Fort Agro: Eficiência e Confiabilidade para Sua
              Fazenda
            </h1>
            <p class="lead">
              Na Fort Agro, entendemos a importância de um equipamento confiável
              e eficiente para a rotina de ordenha. Nossa ordenhadeira foi
              projetada com base nas necessidades reais dos produtores,
              oferecendo desempenho superior e contribuindo para o sucesso do
              seu negócio. Com a Fort Agro, você tem a garantia de um produto
              que combina inovação, qualidade e compromisso com a satisfação do
              cliente.
            </p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
              <a target="_blank" rel="noreferrer" href={whatsappUrl()}>
                <button
                  type="button"
                  class="btn btn-success btn-lg px-4 me-md-2"
                >
                  FALE COM UM CONSULTOR
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="container col-xxl-8 px-4 py-5">
        <div class="row flex-lg-row align-items-center g-5 py-5">
          <div class="col-10 col-sm-8 col-lg-6">
            <img
              src={movelFoto}
              class="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
          <div class="col-lg-6">
            <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">
              Ordenhadeira Móvel Fort Agro: Eficiência e Flexibilidade na
              Ordenha
            </h1>
            <p class="lead">
              Descubra a praticidade e eficiência da Ordenhadeira Móvel Fort
              Agro. Projetada para oferecer flexibilidade e desempenho superior,
              nossa ordenhadeira móvel é a solução ideal para produtores que
              precisam de mobilidade e eficiência na ordenha. Com tecnologia de
              ponta e construção robusta, ela garante qualidade do leite e
              bem-estar animal em qualquer ambiente.
            </p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
              <a target="_blank" rel="noreferrer" href={whatsappUrl()}>
                <button
                  type="button"
                  class="btn btn-success btn-lg px-4 me-md-2"
                >
                  FALE COM UM CONSULTOR
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Produtos;
