// utils.js
export const whatsappUrl = () => {
  const userAgent = navigator.userAgent;
  const isMobile = /iPhone|Android|webOS|BlackBerry|iPod/i.test(userAgent);

  const phoneNumber = "1151997029";
  if (isMobile) {
    return `https://api.whatsapp.com/send?phone=${phoneNumber}`;
  }
  return `https://web.whatsapp.com/send?phone=${phoneNumber}`;
};
