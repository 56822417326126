// src/frontEnd/componentes/header/Header.js
import React from "react";
import logoFortAgro from '../../../Images/logoFortAgro.png'

const Header = () => {
  return (
    <header>
      <section class="container">
        <header class="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
          <a
            href="/"
            class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
          >
            <img
              alt="logo fort agro"
              src={logoFortAgro}
              class="bi me-2"
              width="144"
              height="115"
            ></img>
          </a>
{/* 
          <ul class="nav nav-pills ">
            <li class="nav-item ">
              <a
                href="#"
                class="nav-link active bg-success"
                aria-current="page"
              >
                INICIO
              </a>
            </li>
            <li class="nav-item ">
              <a href="#" class="nav-link text-success fw-bolder">
                PRODUTOS
              </a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link text-success fw-bolder">
                SOBRE
              </a>
            </li>
            <li class="nav-item ">
              <a href="#" class="nav-link text-success fw-bolder">
                CONTATOS
              </a>
            </li>
          </ul> */}
        </header>
      </section>
    </header>
  );
};

export default Header;
