import { whatsappUrl } from "../utils/utils";





const Acao = () => {
    return (
      <div class="bg-dark text-secondary px-4 py-5 text-center">
        <div class="py-5">
          <h1 class="display-5 fw-bold text-white">
            Descubra a Revolução na Agropecuária com Fort Agro!
          </h1>
          <div class="col-lg-6 mx-auto">
            <p class="fs-5 mb-4">
              Eleve sua produção ao próximo nível com os produtos inovadores da
              Fort Agro. Nossas soluções são projetadas para oferecer
              eficiência, durabilidade e resultados excepcionais. Não espere
              mais para transformar sua fazenda. Compre agora e experimente a
              diferença com Fort Agro!
            </p>
            <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <a target="_blank" rel="noreferrer" href={whatsappUrl()}>
                <button
                  type="button"
                  class="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
                >
                  <strong>Compre Agora e Transforme Sua Fazenda</strong>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
}


export default Acao;