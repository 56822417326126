import PagHome from "./frontEnd/pages/pagHome/pagHome";


function App() {
  return (
    <>
      <PagHome />
      
    </>
  );
}

export default App;
