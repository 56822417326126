import React from "react";
import {
  FaTruckMoving,
  FaClock,
  FaShieldAlt,
  FaHandHoldingUsd,
  FaCogs,
} from "react-icons/fa";
import "./DiferenciaisProduto.css";

const DiferenciaisProduto = () => {
  const diferenciais = [
    {
      icon: <FaTruckMoving />,
      title: "Mobilidade Total",
      description:
        "Fácil de transportar e operar em diferentes locais da fazenda.",
    },
    {
      icon: <FaClock />,
      title: "Alta Produtividade",
      description:
        "Ordenha múltiplos animais simultaneamente, otimizando tempo e recursos.",
    },
    {
      icon: <FaShieldAlt />,
      title: "Durabilidade",
      description:
        "Construída com materiais resistentes para suportar condições adversas.",
    },
    {
      icon: <FaHandHoldingUsd />,
      title: "Higiene Assegurada",
      description:
        "Sistema de limpeza automática que mantém a qualidade do leite.",
    },
    {
      icon: <FaCogs />,
      title: "Tecnologia Avançada",
      description:
        "Controle digital preciso para uma ordenha personalizada e eficiente.",
    },
  ];

  return (
    <section className="diferenciais-produto">
      <h2>Diferenciais da Ordenhadeira Móvel Fort Agro</h2>
      <div className="diferenciais-lista">
        {diferenciais.map((diferencial, index) => (
          <div key={index} className="diferencial-item">
            <div className="diferencial-icone">{diferencial.icon}</div>
            <h3>{diferencial.title}</h3>
            <p>{diferencial.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DiferenciaisProduto;
